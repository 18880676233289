import PageAccount from '@/components/PageAccount';

export default PageAccount;

export async function getStaticProps() {
  return {
    props: {
      path: '/login',
      description:
        'Über LOTTO.de können Sie sich mit E-Mail-Adresse und Passwort direkt bei der Landeslotteriegesellschaft Ihres Bundeslandes anmelden. ',
      variant: 'login',
      title: 'Login auf LOTTO.de',
    },
  };
}
