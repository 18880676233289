import BackButton from '@/components/BackButton';
import { CmsPage } from '@/components/CmsInterfaces';
import { GameFooter } from '@/components/GameFooter';
import GameFormContainer from '@/components/GameFormContainer';
import { GameFormVariant } from '@/components/GameFormVariant';
import GameProvider from '@/components/GameProvider';
import Header from '@/components/Header';
import Page from '@/components/Page';

interface PageAccountProps extends CmsPage {
  title: string;
  variant: GameFormVariant;
}

export default class PageAccount extends Page<PageAccountProps> {
  render() {
    return (
      <GameProvider>
        <Header backButton={<BackButton />} hideNav />
        <div className="Game">
          <div className="Game__main">
            <div className="Game__container">
              <div>
                <GameFormContainer
                  initialVariant={this.props.variant}
                  onClickBackButton={this.props.onClickBackButton}
                  doneCallback={undefined}
                />
              </div>
            </div>
          </div>
          {!this.props.gameTemplate && <GameFooter />}
        </div>
      </GameProvider>
    );
  }
}
